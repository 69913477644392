import Vue from 'vue'

// axios
import axios from 'axios'

import store from '../store'

const actionScope = 'loader'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: `${process.env.VUE_APP_URL_API}/`,
    headers: {Accept: 'application/json'},
})
axiosIns.showLoader = true
let requestsPending = 0
const req = {
    pending: () => {
        // eslint-disable-next-line no-plusplus
        requestsPending++

        if (axiosIns.showLoader) {
            store.dispatch(`${actionScope}/show`)
        }
    },
    done: () => {
        // eslint-disable-next-line no-plusplus
        requestsPending--
        if (requestsPending <= 0) {
            if (axiosIns.showLoader) {
                store.dispatch(`${actionScope}/hide`)
            }
        }
    },
}

axiosIns.interceptors.request.use(
    request => {
        const authToken = localStorage.getItem('fronteira-ac-token')
        if (authToken) {
            request.headers.Authorization = `Bearer ${authToken}`
        }
        const showLoading = request.loading === undefined ? true : request.loading
        if (showLoading) {
            req.pending()
        }
        return request
    },
    error => {
        // eslint-disable-next-line no-plusplus
        requestsPending--
        req.done()
        Promise.reject(error)
    },
)
axiosIns.interceptors.response.use(
    response => {
        req.done()
        if (response.status >= 200 && response.status < 300) {
            if (response.headers['content-disposition'] && response.headers['content-disposition'].indexOf('attachment') > -1) {
                return response
            }
            if (response.headers['content-type'] !== 'application/json') {
                return response.data
            }

            if (response.data.meta || response.data.error) {
                return response.data
            }
            return response ? (response.data.data) : false
        }
        return false
    },
    async error =>  {
        req.done()
        if (!error.response) {
            return {
                api_status: 0,
                api_statusText: 'response error',
                data: [],
            }
        }

        const {status, data, statusText} = error.response

        if (status === 401 && (

            !window.location.pathname.startsWith('/login')
            && !window.location.pathname.startsWith('/perdi-minha-senha')
            && !window.location.pathname.startsWith('/recuperar-senha')
        )) {

                const originalConfig = error.config
            if (originalConfig.url === "oauth/token" && originalConfig._retry) {
                localStorage.clear()
                window.location.href = '/login'
                return Promise.reject(_error)
            }
                if (originalConfig.url !== "oauth/token" && error.response) {
                    // Access Token was expired
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true
                        try {
                            let refreshToken = localStorage.getItem('refreshToken')
                            const rs = await axiosIns.post("oauth/token", {

                                'grant_type': 'refresh_token',
                                'refresh_token': refreshToken,
                                'client_id': process.env.VUE_APP_CLIENT_ID,
                                'client_secret': process.env.VUE_APP_CLIENT_SECRET,
                            })

                            if (rs.error) {
                                localStorage.clear()
                                window.location.href = '/login'
                                return Promise.reject(_error)
                            }

                            localStorage.setItem('accessToken', rs.access_token)
                            localStorage.setItem('refreshToken', rs.refresh_token)

                            return axiosIns(originalConfig)
                        } catch (_error) {

                        }
                    }
                }
                return
            }
         //   localStorage.clear()
          //  window.location.href = '/login'

        return {
            error: status,
            api_statusText: statusText,
            error_message: data.error_message || data.message,
            errors: data.errors,
            data: data.data,
        }
    },
)
Vue.prototype.$http = axiosIns
window.http = axiosIns
window.$http = axiosIns
export default axiosIns
