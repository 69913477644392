const marketplaces = [
    {
        path: '/marketplaces',
        name: 'marketplaces.list',
        component: () => import('@/views/marketplace/products/Home.vue'),
        meta: {
            roles: [5,1,6],
        }
    },
    {
        path: '/marketplaces/:uuid/products',
        name: 'marketplaces.product.list',
        component: () => import('@/views/marketplace/products/List.vue'),
        meta: {
            roles: [5,1,6],
        }
    },
]

export default marketplaces
