<template>
  <b-card no-body class="p-1" md="12">
    <b-row align-v="center">
      <b-col md="8" >
        <div>
          <b-button v-if="backButton" variant="flat-primary" class="btn-icon float-left" @click="onBack">
            <feather-icon icon="ArrowLeftIcon"/>
          </b-button>
          <app-breadcrumb :title="name" />
        </div>
      </b-col>
      <b-col
        :md="colSlotSize"
        class="text-right"
      >
        <slot/>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'

export default {
  name: 'PageHeader',
  components: {AppBreadcrumb},
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    backButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    backTo: {
      type: String,
      default: '',
      required: false,

    },
  },
  data: () => ({colSlotSize: 4,}),
  mounted() {

  },
  methods: {
    onBack() {
      if (this.backTo !== '') {
        this.$router.push(this.backTo)
        return
      }
      this.$router.back()
    },
  },
}
</script>

<style scoped>

</style>
