const order = [
    {
        path: '/affiliates',
        name: 'affiliate.index',
        component: () => import('@/views/affiliates/Affiliates.list'),
        meta: {
            pageTitle: 'Afiliados',
            roles: [1, 2, 5,6],
            breadcrumb: [
                {
                    text: 'Listar',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/affiliates/show-commissions',
        name: 'affiliates.commissions.list',
        component: () => import('@/views/affiliates/AffiliatesListCommissions'),
        meta: {
            roles: [1, 2, 5,6],
            pageTitle: 'Comissões',
        },
    },
    {
        path: '/affiliates/user-commissions',
        name: 'affiliates.user-commissions.list',
        component: () => import('@/views/affiliates/AffiliatesListUserCommissions'),
        meta: {
            roles: [1, 2, 5, 4,6],
            pageTitle: 'Comissões a receber',
        },
    },
]

export default order
