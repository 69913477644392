import Vue from 'vue'

const ApiSearch = {
  meta: { page: 1, per_page: 25 },
  async get(endPoint, meta, search, includes) {
    if (meta === null || meta === undefined) {
      meta = { page: 1, per_page: 25 }
    }
    let stringQuery = '?'
    if (endPoint.indexOf('?') !== -1) {
      stringQuery = '&'
    }
    const searchString = this.parseSearchString(search)
    let url = `${endPoint}${stringQuery}page=${meta.page || 1}&per_page=${meta.per_page || 25}`
    const parts = []
    if (searchString !== '') {
      parts.push(`search=${searchString}`)
    }

    const searchURL = new URL(window.location)
    searchURL.searchParams.set('search', searchString)
    searchURL.searchParams.set('page', meta.page || 1)
    searchURL.searchParams.set('per_page', meta.per_page || 25)
    window.history.pushState({}, '', searchURL)
    if (includes !== '') {
      parts.push(`include=${includes}`)
    }
    if (meta.sort_field) {
      parts.push(`sort_field=${meta.sort_field}`)
    }
    if (meta.sort_order) {
      parts.push(`sort_order=${meta.sort_order}`)
    }
    url += `&${parts.join('&')}`
    return window.http.get(url)
  },
  parseSearchString(search) {
    const currentSearch = []
    // eslint-disable-next-line no-restricted-syntax
    for (const el in search) {
      if (search[el] !== null) {
        let currentValue = this.getSearchValue(search[el])
        if (currentValue.value) {
          currentValue = currentValue.value
        }
        currentSearch.push(`${el}:${currentValue}`)
      }
    }

    return currentSearch.join(';')
  },

  getSearchValue(value) {
    let currentValue = value
    if (currentValue instanceof Array) {
      const partialValue = []
      for (const part of currentValue) {
        let partValue = part
        if (part.value) {
          partValue = part.value
        }
        partialValue.push(partValue)
      }
      return partialValue.join(',')
    }
    if (currentValue.value) {
      currentValue = currentValue.value
    }

    return currentValue
  },
}
Vue.prototype.$search = ApiSearch
window.apiSearch = ApiSearch
export default ApiSearch
