<template>
  <div>
    <label v-if="label" class="vs-select--label">
      {{ label }}
    </label>
    <date-range-picker
      ref="picker"
      v-model="dateRange"
      v-bind="$attrs"
      :locale-data="locale"
      :ranges="currentRanges"
      :opens="opens"
      :auto-apply="true"
      v-on="$listeners"
    >
      <div slot="input" slot-scope="picker" class="date-range-picker-date">
        <span v-if="!picker.startDate" style="color: var(--gray)"> {{ placeholder }} </span>
        <span v-else>
          <div v-if="picker.startDate">
            {{ picker.startDate | moment(formatPresentation) }} - {{ picker.endDate | moment(formatPresentation) }}
          </div>
        </span>
      </div>
      <!--    footer slot-->
      <div slot="footer" class="slot">
        <b-col sm="12" class="text-right pb-1">
          <b-button class="btn-sm" variant="outline-secondary" @click="clear">
            Limpar
          </b-button>
        </b-col>
      </div>

    </date-range-picker>

  </div>
</template>

<script>
import moment from 'moment-timezone'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'I2FormDateRange',

  components: {
    DateRangePicker,
  },
  props: ['value', 'name', 'label', 'placeholder', 'initialValue'],

  data: vm => ({
    isPlaceholder: false,
    dateRange: {},
    currentDate: {},
    opens: 'left',
    locale: {
      direction: 'ltr',
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: 'Aplicara',
      cancelLabel: 'Cancelar',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1,
      showWeekNumbers: false,
    },
    formatPresentation: 'DD/MM/YYYY',
    formatModel: 'YYYY-MM-DD',
  }),

  computed: {
    currentRanges() {
      const range = {}
      range.Hoje = [
        moment().toDate(),
        moment().toDate(),
      ]

      range.Ontem = [
        moment().subtract(1, 'days').toDate(),
        moment().subtract(1, 'days').toDate(),
      ]

      range['Esta Semana'] = [
        moment().startOf('week').toDate(),
        moment().endOf('week').toDate(),
      ]

      range[('Última Semana')] = [
        moment().subtract(1, 'week').startOf('week').toDate(),
        moment().subtract(1, 'week').endOf('week').toDate(),
      ]

      range[('Este mês')] = [
        moment().startOf('month').toDate(),
        moment().endOf('month').toDate(),
      ]

      range[('Último mês')] = [
        moment().subtract(1, 'month').startOf('month').toDate(),
        moment().subtract(1, 'month').endOf('month').toDate(),
      ]

      range['Próximo mês'] = [
        moment().add(1, 'month').startOf('month').toDate(),
        moment().add(1, 'month').endOf('month').toDate(),
      ]

      range['Este ano'] = [
        moment().startOf('year').toDate(),
        moment().endOf('year').toDate(),
      ]
      range['12 meses'] = [
        moment().subtract(12, 'months').toDate(),
        moment().toDate(),
      ]

      return range
    },
  },

  watch: {
    value(date) {
      if (date === null || date === '') {
        this.dateRange = {
          startDate: null,
          endDate: null,
        }
      }
      this.$emit('input', date)
    },

    dateRange(date) {
      if (date.startDate === null) {
        return
      }
      this.isPlaceholder = false
      this.$emit('input', [
        moment(date.startDate).format(this.formatModel),
        moment(date.endDate).format(this.formatModel),
      ])
    },
  },
  mounted() {
    this.isPlaceholder = !!this.placeholder
    if (!this.placeholder) {
      this.dateRange = {
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day'),
      }
    }

    if (this.initialValue) {
      this.dateRange = {
        startDate: this.initialValue[0],
        endDate: this.initialValue[1],
      }
    }
  },
  methods: {
    clear() {
      this.dateRange.startDate = null
      this.dateRange.endDate = null
      this.$emit('input', null)
      this.$refs.picker.togglePicker(false)
    },
  },
}
</script>

<style lang="scss">
.vue-daterange-picker {
  width: 100% !important;
}

.dropdown-menu {
  transform: none;
}

.date-range-picker-date {
  margin-top: 5px;
  color: #757575;
}

.reportrange-text {
  width: 100%;
  height: 38px;
  border-radius: 5px;
}
</style>
