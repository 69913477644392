import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import loader from './loader'
import app from './app'
import appConfig from './app-config'
import auth from './auth/auth'
import verticalMenu from './vertical-menu'
import storeSaleChannels from './stores/sale_channels'
import route from './route'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loader,
    auth,
    storeSaleChannels,
    route
  },
  strict: process.env.DEV,
})
