import moment from 'moment-timezone'

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{6,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?((?:\/\w+)|(?:-\w+))*\/?(?![^<]*(?:<\/\w+>|\/?>))/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
export const dateFormatValidator = (val, args) => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return moment(val, args[0], true).isValid()
}
export const timeFormatValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return moment(val, 'HH:mm', true).isValid()
}

export const notEmptyValidator = value => value.trim() !== ''
