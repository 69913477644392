const state = {
    last_route: ''
}

const getters = {
    lastRoute: state => {
        return state.last_route
    }
}

const mutations = {
    // eslint-disable-next-line no-shadow
    SET_LAST_ROUTE(state, payload) {
        state.last_route = payload
    },
}

const actions = {
    setLastRoute: async ({commit}, route) => {
        commit('SET_LAST_ROUTE', route)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
