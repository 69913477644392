const order = [
  {
    path: '/orders',
    name: 'orders.index',
    component: () => import('@/views/orders/OrderList'),
    meta: {
      pageTitle: 'Pedidos',
      roles:[1, 2, 5,6],
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:uuid/show',
    name: 'order.show',
    component: () => import('@/views/orders/OrderShow'),
    meta: {
      roles:[1, 2, 5,6],
      pageTitle: 'Detalhe do Pedido',
      breadcrumb: [
        {
          text: 'Lista',
          active: false,
          to: '/orders',
        },
        {
          text: 'Detalhe',
          active: true,
        },
      ],
    },
  },
]

export default order
