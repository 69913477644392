const state = {
  user: null,
}

const getters = {

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_AUTH_USER(state, payload) {
    state.user = payload
  },
}

const actions = {
  me: async ({ commit }) => {
    try {
      const data = await window.http.get('api/auth/me')
      if (data.error) {
        return null
      }
      commit('SET_AUTH_USER', data)

      localStorage.setItem('userData', JSON.stringify(data))
      return data
    } catch (error) {
      return error
    }
  },
  setUser: async ({ commit }, user) => {
    commit('SET_AUTH_USER', user)
  },

  setToken: async ({ commit }, payload) => {
    const data = {
      token: payload.token,
      type: 'web',
    }
    const response = await window.http.post(`v2/users/${payload.user}/push-token`, data)
    if (response.error) {
      return null
    }
    return 'ok'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
