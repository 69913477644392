const order = [
    {
        path: '/system',
        name: 'system.menu',
        component: () => import('@/views/system/SystemMenu.vue'),
        meta: {
            pageTitle: 'Opções do Sistema',
            roles: [5,],
        },
    },
    {
        path: '/system/queues',
        name: 'system.queues.list',
        component: () => import('@/views/system/QueuesList.vue'),
        meta: {
            pageTitle: 'Status da Fila',
            roles: [5],
        },
    },
    {
        path: '/system/jobs',
        name: 'system.job.list',
        component: () => import('@/views/system/JobList.vue'),
        meta: {
            pageTitle: 'Processos no Fila',
            roles: [5],
        },
    },

]

export default order
