const users = [
  {
    path: '/banners',
    name: 'banners.list',
    component: () => import('@/views/banners/BannerList.vue'),
    meta: {
      pageTitle: 'Configurações',
      roles: [1, 2, 5],
    }
  },
]

export default users
