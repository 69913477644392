const delivery = [
  {
    path: '/delivery-settings',
    name: 'delivery.settings',
    component: () => import('@/views/delivery/ProductDeliverySettings'),
    meta: {
      pageTitle: 'Configurações',
      roles: [1, 2, 5],
      breadcrumb: [
        {
          text: 'Configurações de Entrega Local',
          active: true,
        },
      ],
    },
  },
]

export default delivery
