const users = [
  {
    path: '/clients',
    name: 'clients.list',
    component: () => import('@/views/clients/ClientList.vue'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Listagem de Clientes',
          active: true,
        },
      ],
      roles: [5]
    },
  },
  {
    path: '/clients/:uuid/edit',
    name: 'clients.edit',
    component: () => import('@/views/clients/ClientForm.vue'),
    meta: {
      pageTitle: 'Clientes',
      roles: [5],
      breadcrumb: [
        {
          text: 'Listagem de Clientes',
          active: false,
          to: '/clients',
        },
        {
          text: 'Cadatstro',
          active: true,
        },
      ],
    },
  },
]

export default users
