<template>
  <b-button
    v-ripple.400
    v-bind="$attrs"
    class="btn-icon"
    :variant="variant"
    v-on="$listeners"
  >
    <i
      v-if="faIcon"
      :class="`fas ${faIcon}`"
    />
    <b-icon
      v-if="bIcon"
      :icon="bIcon"
    />
    <feather-icon
      v-if="icon"
      :icon="icon"
      style="font-size: 1.7em;"
    >{{ icon }}
    </feather-icon>
    <slot />
  </b-button>

</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  name: 'I2IconButton',
  directives: {
    Ripple,
  },
  // eslint-disable-next-line vue/prop-name-casing,vue/require-prop-types
  props: ['fa-icon', 'icon', 'variant', 'b-icon'],
}
</script>
