<template>
  <b-row
    v-if="$route.meta.breadcrumb || current_title"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ current_title }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="getRoute(item.to)"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    />
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,

  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      current_title: '',
    }
  },
  watch: {
    title(value) {
      if (value !== '') {
        this.current_title = value
      }
    },
  },
  mounted() {
    this.current_title = this.$route.meta.pageTitle
    if (this.title !== '') {
      this.current_title = this.title
    }
  },

  methods: {
    getRoute(route) {
       if (route?.type === 'history') {
         let lastRoute = this.$store.state.route.last_route
         if (lastRoute === this.$route.fullPath ||  lastRoute === '') {
           lastRoute = route.default
         }
         return lastRoute
       }
       return route
    }
  }
}
</script>
