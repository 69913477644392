import Vue from 'vue'

Vue.filter('percentage', value => new Intl.NumberFormat(
  'pt-BR',
  {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
).format(value / 100))
