const state = {
    sale_channels: null,
}

const getters = {

}

const mutations = {
    // eslint-disable-next-line no-shadow
    SET_CHANNELS(state, payload) {
        state.sale_channels = payload
    },
}

const actions = {
    salesChannels: async ({ commit }, store) => {
        try {
            const data = await window.http.get(`v2/stores/${store}/sales-channels?include=itemsCount`)
            if (data.error) {
                return null
            }
            commit('SET_CHANNELS', data)
            return data
        } catch (error) {
            return error
        }
    },
    getChannelsProducts: async ({ commit }, data) => {
        try {
            const data = await window.http.get(`v2/stores/${data.store}/sales-channels/${data.sale_channel}/products`)
            if (data.error) {
                return null
            }
            commit('SET_CHANNELS', data)
            return data
        } catch (error) {
            return error
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
