import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
    return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const userRole = () => {
    const userData = localStorage.getItem('userData')
    if (userData !== null) {
        return JSON.parse(userData).type
    }
    return 0
}
export const getUserData = (refresh = false) => {
    let emptyUser = {
        type: 0,
        email: '',
        name: '',
    }
    const userData = localStorage.getItem('userData')
    if (userData !== null) {
        return JSON.parse(userData)
    }
    if (refresh) {
        let user = window.http.get('/me')
        if (user.status !== 200) {
            return emptyUser
        }
        return user
    }
    return emptyUser
}

export const userCanUseNetwork = user => {
    return [5,6].includes(user?.perfil_id || 0) && user?.store?.is_network_member
}
