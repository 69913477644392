import Vue from 'vue'

const messageBox = {
    install: function (Vue, options) {
        Vue.prototype.$messageBox = {
            async confirm(title, msg, yesLabel = 'Sim', noLabel = 'Não') {
                const vm = new Vue()
                return await vm.$bvModal.msgBoxConfirm(msg, {
                    title: title,
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: yesLabel,
                    cancelTitle: noLabel,
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: true,
                    centered: true,
                })
            }
        }
    }
}

Vue.use(messageBox)
